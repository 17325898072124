import { LOGIN_START, EMAIL, LOGOUT_START } from '../actionType/';

export const loginAction = (payload) => ({
    type: LOGIN_START,
    payload,
});

export const logoutAction = () => ({
    type: LOGOUT_START,
});
