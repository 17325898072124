import { USER_LIST_START, USER_EDIT_START, CREATE_NEW_USER_START } from '../actionType';

export const userListAction = (pageNumber, size) => ({
    type: USER_LIST_START,
    pageNumber,
    size,
});

export const userStatusAction = (id, status) => ({
    type: USER_EDIT_START,
    id,
    data: { status },
});

export const createUserAction = (data) => ({
    type: CREATE_NEW_USER_START,
    data,
});

export const editUserAction = (data) => {
    const { _id, email, ...updatedData } = data;
    return {
        type: USER_EDIT_START,
        data: updatedData,
        id: _id,
    };
};
